import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ProductBanner from "../product/product-banner/ProductBanner";
import { sellerCategory } from "../../utils/apis/SellerApi/SellerApi";
import SubCategoryInnerPage from "./SubCategoryInnerPage/SubCategoryInnerPage";

const SubCateogryProduct = () => {
  const navigate = useNavigate();

  const [sellerData, setSellerData] = useState();
  const getSeller = async () => { 
    const storedSellerId = localStorage.getItem("seller_id");

    if (storedSellerId) {
      const res = await sellerCategory(storedSellerId);

      if (res?.success) {
        setSellerData(res?.data);
      }
    } else {
      navigate("/");
    }
  };

  console.log("categoryDataaaaaa", sellerData);

  useEffect(() => {
    getSeller();
  }, []);
  return (
    <>
      <ProductBanner sellerData={sellerData} />
      {/* <SeclectByCategory sellerData={sellerData} /> */}
      <SubCategoryInnerPage />
    </>
  );
};

export default SubCateogryProduct;

import React, { useState, useContext, useEffect } from "react";
import "./Product_Card.css";
import { Card, CardFooter } from "react-bootstrap";
import SupermarketModal from "../../Common_modal/Supermarket-modal/SupermarketModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faHeart } from '@fortawesome/free-solid-svg-icons';


import { Context } from "../../../utils/context";
import { ShimmerPostItem } from "react-shimmer-effects";
import { useNavigate } from 'react-router-dom';
import { AddToCart } from "../../../utils/apis/addToCart/addToCart";

import { addWishlist, getWishlist } from "../../../utils/apis/Wishlist/Wishlist";

function Product_Card({ product_id, price, subText, text, image, variant_id, product }) {

  const [activeHearts, setActiveHearts] = useState(false);

  const { shimmerLoader, setShimmerLoader, addCartModal, setAddCartModal, wishlistData, wishlistContext, triggerCartUpdate } = useContext(Context);
  const navigate = useNavigate();
  const { signin } = useContext(Context);
  const handleHeartClick = async (data) => {
    setShimmerLoader(true);
    if (signin) {
      const res = await addWishlist(data);
      if (res?.success) {
        wishlistContext();
      }
      setShimmerLoader(false);
    } else {
      setShimmerLoader(false);
      navigate('/login');
    }

  };

  const handleNavigate = async () => {
    const id = localStorage.setItem('product_id', product_id);
    console.log("ididididi", id);
    // navigate('/vendor-page');

    navigate('/vendor-page');
    // window.location.href = '/vendor-page';
  };

  useEffect(() => {
    wishlistContext();
  }, [signin]);
  return (
    <>


      {shimmerLoader ? (
        <ShimmerPostItem card title cta
          imageHeight={180}
        />
      ) : (
        <div className="main-product-img-main mt-md-4 mb-5">
          <Card>

            <div className="main-product-main mx-auto" >
              <Card.Img
                onClick={handleNavigate}
                variant="top"
                src={image}
                className="product-img"
                alt="..."
              />
            </div>

            <div className="add-cart-sec">
              <div onClick={() => { AddToCart({ product_id: product_id, variant_id: variant_id }); setAddCartModal(true); triggerCartUpdate() }}>
                <p>
                  <FontAwesomeIcon
                    icon={faCartShopping}
                    className="cart-logo me-2 "
                  />
                 <span> Add To Cart</span>
                </p>
              </div>

              <div>
                <p onClick={() => handleHeartClick({ product_id: product_id, variant_id: variant_id })}>
                  <FontAwesomeIcon
                    icon={faHeart} // Corrected icon usage
                    className={
                      wishlistData?.some(item => item.product_id === product_id && item.variant_id === variant_id) ?
                        "cart-logo me-2 active" :
                        "cart-logo me-2"
                    }
                  />
                  <span>Added To Wishlist</span>
                </p>
              </div>
            </div>

            <Card.Body>
              <Card.Text className=" text-holder  mt-1">
                <h4>{text}</h4>
                <span>{subText}</span>

                <p className="card-second-line">₹ {price}/-</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      )}


      <SupermarketModal show={addCartModal} onHide={() => setAddCartModal(false)} />
    </>
  );
}

export default Product_Card;
import React, { useEffect, useState, useContext } from 'react';


import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Product_Card from '../../Common-Component/Product_Card/Product_Card';
import { Category, SubCategory, ChildSubCategory } from '../../../utils/apis/Product/Product';
import { Context } from '../../../utils/context';
import { Products } from "../../../utils/apis/Product/Product";
import { getData } from '../../../utils/api';
import SubCategoryProductCard from '../../product/sub-category/SubCategoryProductCard/SubCategoryProductCard';
import { useParams } from 'react-router-dom';

const SubCategoryInnerPage = () => {

    const { IMG_URL, selectedCategroryId, selectedSubCategroryId } = useContext(Context);
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [childSubCategory, setChildSubCategory] = useState([]);
    const [products, setProducts] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0); // Track the active tab index

    const [category_id, setCategoryId] = useState("");
    const [sub_category_id, setSubCategoryId] = useState("");
    const [child_sub_category_id, setChildSubCategoryId] = useState("");

    console.log("selectedCategroryId",selectedCategroryId,selectedSubCategroryId)

   
    const getChildSubCategory = async (id) => {

        const sub_category_id = localStorage.getItem('sub_category_id');

        const res = await ChildSubCategory(sub_category_id);
        if (res?.success) {
            await setChildSubCategory(res.data);
            await setChildSubCategoryId(res.data[0]?.id);
        }
    };

    useEffect(() => {
        getChildSubCategory()
    },[])

    const getProduct = async () => {

        // const res = await getData(`/without-login/product/filter?category_id=${category_id || ""}&sub_category_id=${sub_category_id || ""}` +
        //     `&child_sub_category_id=${child_sub_category_id | ""}`);

        const res = await getData(`/without-login/product/filter?category_id=${selectedCategroryId || ""}&sub_category_id=${selectedSubCategroryId || ""}` +
            `&child_sub_category_id=${child_sub_category_id | ""}`);

        if (res?.success) {
            setProducts(res?.data?.data);
        };
    }

    useEffect(() => {
        getProduct();
    }, [category_id, sub_category_id, child_sub_category_id]);


    const handleTabClick = (index, childCategoryId) => {
        setActiveIndex(index);
        setChildSubCategoryId(childCategoryId);
        getProduct();
    };

    console.log("subCategory", subCategory)

    return (
        <section className='Sub_category_sec'>
            <div className='container-lg'>
                <Tab.Container id="left-tabs-example" activeKey={activeIndex}>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-xs-4 mobstickyy'>
                            <h5 className='allcal'>Child Categories</h5>
                            <div className='catlistdiv'>
                            {childSubCategory && childSubCategory?.length > 0 ? <>
                                <Nav variant="pills" className="">
                                    {childSubCategory?.map((data, index) => (
                                        <Nav.Item key={index}>
                                            <Nav.Link
                                                eventKey={index}
                                                onClick={() => handleTabClick(index, data?.id)}
                                                active={data?.id === child_sub_category_id}
                                            >
                                                <img className="catimg" src={IMG_URL + data?.image} alt={data?.name} />
                                                {data?.name}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                                </> : <p style={{ textAlign: 'center' }} className='mt-5'> No Child Category Available</p>}
                            </div>
                        </div>

                        <div className='col-lg-9 col-md-8'>

                            <div className='row'>
                                {products && childSubCategory?.length > 0 && products?.length > 0 ? <>
                                    {products?.map((product, idx) => (
                                        <div className='col-lg-4 col-md-6 col-6' key={idx}>
                                            <Product_Card
                                                image={IMG_URL + product?.image1}
                                                text={product?.name}
                                                product_id={product?.id}
                                                subText={product?.short_description}
                                                price={product?.variants[0]?.s_price}
                                                variant_id={product?.variants[0]?.id}
                                            />
                                        </div>
                                    ))}
                                </> : <p style={{ textAlign: 'center' }} className='mt-5'> No Products Available</p>}
                            </div>



                            {/* <div className='child-prod-list'>
                                <Tab.Content>
                                    {category && category?.map((data, index) => (
                                        <Tab.Pane eventKey={index} key={index}>
                                            {subCategory && subCategory?.length > 0 ?
                                                <>
                                                    <h5 className='allcal'>Sub Categories</h5>
                                                    <div className='subcatdiv'>

                                                        <Tab.Container id="left-tabs-example" defaultActiveKey={subCategory && subCategory[0]?.id}>
                                                            <Nav variant="pills" className="mb-4">
                                                                {subCategory && subCategory?.map((subcategory, index) =>
                                                                    <Nav.Item key={index}>
                                                                        <Nav.Link
                                                                            active={sub_category_id === subcategory?.id}
                                                                            eventKey={subcategory?.id}
                                                                            onClick={() => { getChildSubCategory(subcategory?.id); setSubCategoryId(subcategory?.id) }}
                                                                        >
                                                                            {subcategory?.name}
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                )}

                                                            </Nav>

                                                        </Tab.Container>

                                                    </div>
                                                    {childSubCategory && childSubCategory?.length > 0 ?
                                                        <>
                                                            <h5 className='allcal'>Child Categories</h5>
                                                            <div className='subcatdiv'>

                                                                <Tab.Container id="left-tabs-example" defaultActiveKey={childSubCategory && childSubCategory[0]?.id}>
                                                                    <Nav variant="pills" className="mb-4">
                                                                        {childSubCategory && childSubCategory?.map((childcategory, index) =>
                                                                            <Nav.Item key={index}>
                                                                                <Nav.Link
                                                                                    active={child_sub_category_id === childcategory?.id}
                                                                                    eventKey={childcategory?.id}
                                                                                    onClick={() => { setChildSubCategoryId(childcategory?.id) }}
                                                                                >
                                                                                    {childcategory?.name}
                                                                                </Nav.Link>
                                                                            </Nav.Item>
                                                                        )}

                                                                    </Nav>

                                                                </Tab.Container>

                                                            </div>
                                                        </> : <></>}
                                                </> : <></>}
                                            <div className='row'>
                                                {products && products?.length > 0 ? <>
                                                    {products?.map((product, idx) => (
                                                        <div className='col-lg-4 col-md-6 col-6' key={idx}>
                                                            <Product_Card
                                                                image={IMG_URL + product?.image1}
                                                                text={product?.name}
                                                                product_id={product?.id}
                                                                subText={product?.short_description}
                                                                price={product?.variants[0]?.s_price}
                                                                variant_id={product?.variants[0]?.id}
                                                            />
                                                        </div>
                                                    ))}
                                                </> : <p style={{ textAlign: 'center' }} className='mt-5'> No Products Available</p>}
                                            </div>

                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </div> */}


                        </div>
                    </div>
                </Tab.Container>
            </div >
        </section >
    );
};

export default SubCategoryInnerPage;

import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LogIn from "../LogIn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { Errors, Placeholders } from "../../../utils/errors";
import { RegxExpression } from "../../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import PasswordChecklist from "react-password-checklist";

import { Context } from "../../../utils/context";
import Cookies from "js-cookie";

import { loginCart } from "../../../utils/apis/addToCart/addToCart";
import { Register } from "../../../utils/apis/login/Login";
import RegisterModal from "../../Common_modal/RegisterModal/RegisterModal";

function Register_Form() {
  const [showLogin, setShowLogin] = useState(false);

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const [modalShow, setModalShow] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [iconOne, setIconOne] = useState(faEye);
  const [iconTwo, setIconTwo] = useState(faEye);

  const navigate = useNavigate();

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    }
  };

  const { signin, setSignin, shimmerLoader,setShimmerLoader  } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    setShimmerLoader(true);
    if(password === passwordAgain){
      const res = await Register(data);
      if (res?.success) {
        await Cookies.set("local_mandai_security", res?.data?.token);
        setSignin(true);
        await loginCart();
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          setShimmerLoader(false);
          navigate(-1);
        }, 3000);
      } else {
        setShimmerLoader(false);
        if (res?.message?.both) {
          setError("email", { message: res?.message?.both });
          setError("contact_no", { message: "" });
        }
        if (res?.message?.email) {
          setError("email", { message: res?.message?.email });
        }
        if (res?.message?.contact_no) {
          setError("contact_no", { message: res?.message?.contact_no });
        }
      }
    }else{
      setError("re_password", { message: "Password Does not match" });
    }
    


  };

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  return (
    <>
      {showLogin ? (
        <LogIn />
      ) : (
        <div className="LogIn">
          <div className="row bg-color me-0 ms-0">
            <div className="col-md-7 p-0">
              <div className="row justify-content-center me-0 ms-0  ">
                <div className="col-md-6">
                  <div className="back_to_span">
                    <Link to={"/"}>
                      <p>Back to Home</p>
                    </Link>
                  </div>

                  <div className="heading-holder">
                    <h4>Register</h4>
                  </div>

                  <div className="log_in_form">
                    <Form>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="text"
                          name="First_Name"
                          placeholder="Enter First Name"
                          {...register("first_name", {
                            required: "First name required",

                          })}
                          className={classNames("", {
                            "is-invalid": errors?.first_name,
                            "is-valid": getValues("first_name"),
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="Enter Email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: RegxExpression.email,
                              message: "Invalid email address",
                            },
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.email,
                            "is-valid": getValues("email"),
                          })}
                        />

                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <span style={{ color: "green", fontSize: "1.2em" }}>
                            ✔
                          </span>
                        </div>
                      </Form.Group>
                      {errors?.email && (
                        <sup className="text-danger">
                          {errors?.email?.message}
                        </sup>
                      )}

                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.contact_no,
                            "is-valid": getValues("contact_no"),
                          })}
                          type="text"
                          name="contact_no"
                          placeholder="Enter Mobile Number"
                          {...register("contact_no", {
                            required: "Field is required",
                            minLength: {
                              value: 10,
                              message:
                                "Number should be at least 10 characters",
                            },
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.phone.test(event.key)) {
                              if (event.key !== "Backspace") {
                                event.preventDefault();
                              }
                            }
                          }}
                          maxLength={10}
                        />
                      </Form.Group>
                      {errors?.contact_no && (
                        <sup className="text-danger">
                          {errors?.contact_no?.message}
                        </sup>
                      )}
                      <Form.Group className="mb-3" controlId="">
                        <div className="password-field-holder">
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password*"
                            name="password"
                            {...register("password", {
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters long",
                              },
                              pattern: {
                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                message:
                                  "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                              },
                            })}
                            onChange={(e) => setPassword(e.target.value)}
                          />

                          <div className="eye-icon-holder ">
                            <FontAwesomeIcon
                              icon={iconOne}
                              className="eye-icon"
                              onClick={() =>
                                togglePasswordVisibility("password")
                              }
                            />
                          </div>
                        </div>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="">
                        <div className="password-field-holder">
                          <Form.Control
                            type={showPasswordOne ? "text" : "password"}
                            placeholder="Re-Enter Password*"
                            name="re_password"
                            {...register("re_password", {
                              required: "Re-Password is required",
                            })}
                            onChange={(e) => setPasswordAgain(e.target.value)}
                          />
                          <div className="eye-icon-holder ">
                            <FontAwesomeIcon
                              icon={iconTwo}
                              className="eye-icon"
                              onClick={() =>
                                togglePasswordVisibility("reenterPassword")
                              }
                            />
                          </div>
                        </div>
                      </Form.Group>

                      {errors?.re_password && (
                        <sup className="text-danger">
                          {errors?.re_password?.message}
                        </sup>
                      )}
                      {password && (
                        <PasswordChecklist
                          rules={[
                            "minLength",
                            "specialChar",
                            "number",
                            "capital",
                            "match",
                          ]}
                          minLength={8}
                          value={password}
                          valueAgain={passwordAgain}
                          onChange={(isValid) => { }}
                          style={{
                            fontSize: "10px",
                            padding: "4px",
                            margin: "0",
                          }}
                        />
                      )}
                    </Form>

                    <div className="btn-holder">
                      <Common_Button
                        disable={shimmerLoader}
                        className="SubmitBtn"
                        onClick={handleSubmit(onSubmit)}
                        text="  Create"
                      />
                    </div>

                    <div className="create_account mb-5">
                      <div className="login_text">
                        <p>
                          Already have an account? {/* <Link to={"/login"}> */}
                          <span
                            className="login_text"
                            onClick={handleLoginClick}
                          >
                            LOGIN
                          </span>
                          {/* </Link> */}
                        </p>
                      </div>
                    </div>

                    {/* <div className="log_in_with">
                      <p>or register with social accounts</p>
                    </div> */}

                    {/* <div className="social_img_holder">
                      <img
                        className="social_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/log_in/google.png"
                        }
                        alt="social_img"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 p-0">
              <div className="img-section">
                <div className="img-holder">
                  <img
                    className="log_in_img"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/log_in/log_in.png"
                    }
                    alt="log_in_img"
                  />
                </div>
              </div>
            </div>

            <RegisterModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Register_Form;

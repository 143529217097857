import React, { useContext } from 'react'
import './SubCategoryProductCard.css'
import { Context } from '../../../../utils/context'

const SubCategoryProductCard = ({ name, image }) => {

    const {IMG_URL} = useContext(Context)

    return (
        <div className='SubCategoryProductCard'>
            <div className='img_holder'>
                <img src={IMG_URL + image} className='card_img' />
            </div>
            <div className='text_holder'>
                <p className='product_name'>{name}</p>
            </div>
            <div className='overlay_bg'></div>
        </div>
    )
}

export default SubCategoryProductCard
